import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// import resume from '../assets/CV/Sagar-Satam-CV2019.pdf'

import about1 from '../assets/images/about/about1.jpg'
import about2 from '../assets/images/about/about2.png'
import about5 from '../assets/images/about/about5.jpg'
import about7 from '../assets/images/about/about7.png'
import about8 from '../assets/images/about/about8.jpg'
import awardsgroup from '../assets/images/awardsgroup.png'
import mediumlogo from '../assets/images/mediumlogo.png'
import aboutT2 from '../assets/images/aboutthings/things2.png'
import about1jiva from '../assets/images/aboutjiva/jiva1.png'
import clientgroup from '../assets/images/clientgroup.jpg'





// import award1 from '../assets/images/awards/award1.png'
// import award2 from '../assets/images/awards/award2.png'
// import award3 from '../assets/images/awards/award3.png'
// import award4 from '../assets/images/awards/award4.png'
// import award5 from '../assets/images/awards/award5.png'
// import award6 from '../assets/images/awards/award6.png'
// import award7 from '../assets/images/awards/award7.png'
// import award8 from '../assets/images/awards/award8.png'

const Aboutme = props => (
  <Layout>
    <Helmet
      title="About Me | Portfolio Sagar Satam"
      meta={[
        { name: 'Design', content: 'Portfolio' },
        { name: 'Design', content: 'product design, designer' },
      ]}
    />

    <div id="main" className="alt">
      <section id="one" className="title-block inner">
        <div className="hp-featured">
          <h1 className="hp-featured__title">
            about me
          </h1>
          <div className="hp-featured__content">
            {/* <div className="hp-featured__hr-line" /> */}
            {/* <p className="hp-featured__text">
              Living with good designed products & services can simply the lives
              of people in many ways.
            </p>
            <p className="hp-featured__text">
              I aspire to be a designer that adds value to the lives of people
              as this is what makes Design meaningful to me.{' '}
            </p> */}
            <p className="hp-featured__text">
            <span role="img" aria-labelledby="designeremoji">✏️</span> Designer <br />
              <span role="img" aria-labelledby="engineeremoji">⚙️</span> Engineer <br />
              <span role="img" aria-labelledby="badmintonemoji">🏸</span> Badminton player <br />
              <span role="img" aria-labelledby="f1emoji">🏎️</span> Formula One enthusiast <br />
            </p>
          </div>
        </div>
      </section>

      <section id="content" className="content-wrapper inner">
        
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            {/* <div className="img left">
              <img src={about1} alt="" />
            </div> */}
            <div className="img right">
              <img src={about1} alt="" />
            </div>
          </div>
          {/* <h4>HOW I GOT HERE?</h4>
          <p>
            As an engineer and product designer, I used to love being surrounded
            by products until I realised that in recent decades services are
            becoming a dominant part of our economy. Information Technologies &
            mass digitisation increase the possibilities for services to address
            transient needs of our lives. This has been accompanied by changes
            in lifestyles, customs and the culture of liquid consumer
            expectations. This was when i decided to shift my focus solely on
            products to a Product-Service-System Design.
          </p>
          <h4>HOW I FEEL ABOUT DESIGNING SERVICES?</h4>
          <h2>I love what I do!</h2>
          <p>
            I have been growing as a Service Designer and like taking-on the
            challenges in the different service industries. Understanding users,
            problem solving, critical thinking and working with design systems
            are my forte; it's the first step I take towards looking at the
            bigger holistic picture.
          </p>
          <p>
            I feel Empathy is one of the most important life and design skill i
            am learning to master everyday. It allows me to know how people
            think, feel and behave, allowing me to better understand needs,
            motivations, mindsets and journeys of people i am designing for.
          </p>
          <p>
            After knowing how the details of the experience works, I step away
            from users to think more about the system to look at the bigger
            picture. Considering a wider network of stakeholders enables me to
            unlock value at every stage of the process.
          </p>
          <p>
            This process of moving backwards and forwards across different
            levels of complexity allows me to design and manage all phases of a
            service experience. From end-to-end experience on the user side to
            the front & back office processes from the service-provider point of
            view.
          </p>
          <h4>WHAT DRIVES ME?</h4>
          <h2>I don’t live to design, I design to live!</h2>

          <p>
            I believe in designing thoughtful experiences for people; that
            create value and meaning for them to bring a smile on their faces.{' '}
          </p>
          <img src={about3} alt="" />
          <p>
            The future is in designing living services that can learn, act &
            predict to match people's expectations. These services will need to
            use behavioural heuristics and personal data, with privacy &
            transparency, to constantly wrap themselves around their lives,
            anticipating individual preferences and evolving to meet changing
            needs.
          </p>
          <p>
            However, in recent years the rapid growth of mobile technology has
            brought in an age where time and attention is an increasingly scarce
            resource. As designers, we play a key role in taking responsibility
            for the impact that these digital products and services we build are
            having on people.
          </p>
          <p>
            Overall, I want to create holistic systems where both users and
            business benefit and grow by solving problems in a smart, ethical
            and sustainable way.
          </p>
          <h4>WHAT I LOVE TO DO IN MY FREE TIME?</h4>
          <h2>I enjoy doing things that make me feel free!</h2>
          <p>
            Music, travelling, reading, attending design events and outdoor
            activities to name a few are the ones that i love the most. I am
            constantly looking to learn new things and one way i do this is by
            being open to new experiences. Having lived in India, Singapore,
            Australia and Italy i feel blessed to have had the chance to meet
            people from diverse cultures, mindsets and of course the FOOD! These
            experiences has greatly influenced they way i approach my work and
            my personal life.
          </p> */}
        </div>

        {/* <div className="resume-download">
          <p>download my resume/CV</p>
          <a href={resume} className="resume-download-link" download>
            RESUME
          </a>
        </div> */}
      </section>

      <br />
      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
      <div className="changebg">
      {/* <p className="full-textlarge">
              From being designer #1 to a design team 
              of 25 plus. I was involved in scaling teams 
              and operations across multiple locations and crops.
              </p> */}
            <div className="process">           
            <div className="process-left">
            {/* <p>
              My recent experience was at Jiva Ag, where I designed an 
              AgriTech product & service to uplift the livelihoods of 
              smallholder farmers in rural Indonesia. 
              <br />
              <br />
              I played multiple roles as a Service & UX designer — 
              🔍 the researcher, 🦮 the facilitator, 🎬 the orchestrator, 
              ✍️ the experience designer.
              </p> */}
              <p className="half-textlarge">
              From being designer #1 to a design team 
              of 25 plus. I was involved in scaling teams 
              and operations across multiple locations and crops.
              </p>

            </div>

            

            <div className="process-right">
            <p>
              My recent experience was at Jiva Ag, where I designed an 
              AgriTech product & service to uplift the livelihoods of 
              smallholder farmers in rural Indonesia. 
              <br />
              <br />
              I played multiple roles as a Service & UX designer — 
              🔍 the researcher, 🦮 the facilitator, 🎬 the orchestrator, 
              ✍️ the experience designer.
              </p>
              <p>
              I managed teams and led 6 projects from inception to
               completion, working both autonomously and collaboratively 
               to deliver outcomes on time. 
               <br />
               <br />
               Additionally, I have mentored 
               over 10 designers, creating an environment where they can 
               perform their best and grow.
              </p> 
              <a href="/about-jiva"><u>know more about Jiva →</u></a>
              
           
              </div>

              <div className="img right">
              <img src={about1jiva} alt="" />
              </div>
              
            </div>

            <br />
            <br />



            {/* <p className="full-textlarge">
            Designing Internet of Things (IoT) products & services 
            in smart mobility, smart home, smart healthcare, smart 
            cities and industry 4.0.
              </p> */}

            <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            Designing Internet of Things (IoT) products & services 
            
            </p>
            
            </div>
            <div className="process-right">
            <p>
            I have previous experience working for a European Innovation consultancy focused on designing IoT products & Services in smart mobility, smart home, smart healthcare, smart cities and industry 4.0.
            </p> 
              <p>
              At THINGS, we operated in a fast-paced & agile environment, designing prototypes and pilots to test and learn; to iteratively create impact at scale.
              </p> 
              <a href="/about-things"><u>know more about THINGS →</u></a>
              </div>
              <br />
              <br />
              <br />
              <div className="img right">
              <img src={aboutT2} alt="" />
              </div>
            </div>

      </div>

      <p className="full-textlarge">I enjoy doing things that make me feel free! Every day I look forward to doing absolutely 
              nothing for at least 10-15 minutes. </p>


      {/* <div className="process">           
            <div className="process-left">
              <p className="half-textlarge">I enjoy doing things that make me feel free!</p>
            </div>
            <div className="process-right">
              <p>
              Every day I look forward to doing absolutely 
              nothing for at least 10-15 minutes. 
              <br />
              <br />
              This "nothing" 
              time involves me simply sitting in a quiet place, 
              closing my eyes and taking deep breaths; this helps 
              me relax.   
              </p>            
              </div>
      </div> */}

      <div className="changebg">
      <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">Me beyond work</p>
            </div>
            <div className="process-right">
              <h5>
              Obsessions
              </h5>
              <p>
              Dogs <br />
              Tiramisu <br />
              Football #ggmu <br />
              Formula 1 #forzaferrari <br />
              Shows on Crime, Design & Business
              </p>

              <br />
              
              <h5>
              Currently learning
              </h5>
              <p>
              How to dance <br />
              How to negotiate<br />
              How to cook authentic pasta
              </p> 

              <br />
              
              
              <h5>
              Spontaneous
              </h5>
              <p>
              Eat something new <br />
              Go somewhere new <br />
              Get close to nature and take photos
              </p> 
              
              <br />

              <h5>
              Ways i stay creative
              </h5>
              <p>
              Get away from the computer <br />
              Ask questions and get feedback <br />
              Be a sponge to learning  
              </p>

              </div>
      </div>

      <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            {/* <div className="img left">
              <img src={about1} alt="" />
            </div> */}
            <div className="img left">
              <img src={about7} alt="" />
            </div>
          </div>
        </div>
      </section>


      </div>
      </section>
      </div>
      </div>


     

      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">

      <div className="process">             
            <div className="process-left">
            <p className="half-textlarge">
            Living in Singapore, Australia, India, 
            and Italy over the years has shaped me 
            into a humble, curious, and courageous individual.
            </p>
            </div>
            <div className="process-right">
            <p>
            Living in these cities has given me the chance 
            to experience new things, interact with people 
            from different backgrounds, mindsets and culture.
            </p>
            <p> 
            I also learned to adjust to different surroundings, 
            adapt to new ways of living, be brave & take calculated 
            risks and most importantly develop an open mind.   
            </p>
            <p>These life experiences has greatly influenced
             they way i approach my personal life & my work. 
             </p>            
            </div>
      </div>

      </section>
      </div>
      </div>  


      <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            <div className="img left">
              <img src={about2} alt="" />
            </div>
          </div>
        </div>
      </section>

      
          
         


      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
            
            

            <div className="bluebg">
            <br />
            <p className="full-textlargebluebg">
            We design from who we are.
            <br /> 
            Self-Awareness is vital; our mindset, 
            culture, biases and beliefs reflects 
            in the designs we create. 
            </p>
            <br />
            </div>
              
            
      </section>
      </div>
      </div> */}

      
      
      
      
      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
      <div className="changebg">

      <div className="process">           
            <div className="process-left">
              <h4>What drives me?</h4>
            </div>
            <div className="process-right">
              <p className="half-textlarge">
              I don’t live to design, <br />
              I design to live!
              </p>
              <p>
              Living with good designed products & services
               can simply the lives of people in many ways. 
               I aspire to be a designer that adds value to the 
               lives of people as this is what makes Design 
               meaningful to me. 
              </p>
              <p>
              I have evolved as a designer over the years. 
              Currently we are facing big challenges and wicked
               problems - such as climate issues, mental health, 
               poor well-being of users, addiction to attention sapping 
               technology, personal data exploitation, etc. These are 
               systemic and human problems which are complex at it 
               involves multiple elements, interactions and dimensions.   
              </p>            
              </div>
      </div>

      <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            
            <div className="img left">
              <img src={about5} alt="" />
            </div>
          </div>
        </div>
      </section>


      <div className="process">             
            <div className="process-left">
            <p className="half-textlarge">
            We as designers should be gatekeepers 
            to what we put out in the society.
            </p>
            <p>
            We have a responsibility for the impact 
            products and services we build are having 
            on people that are using them. I feel our 
            designs, the choices we make and the 
            decisions we make while designing are 
            very important. 
            </p>
            <p> 
            The user's experience is made up of design decisions. 
            We need to have the vision to see the unintended 
            consequences our decisions, designs, have on the society.     
            </p>            
            </div>
      </div>

      

      <p className="full-textlarge">
      Personally, the way i want to design is by adopting a 
            human-planet-business centric approach to design; 
            while using technology as an enabler.  
      </p>

      <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            
            <div className="img right">
              <img src={about8} alt="" />
            </div>
          </div>
        </div>
      </section>

      <div className="process">             
            <div className="process-right">
            <p>
            This means designing experiences that 
            create value and well-being for its users, 
            are sustainable for the environment and 
            viable for businesses. I want to create
             holistic systems where both users and 
             business benefit and grow by solving 
             problems in a smart, ethical and sustainable way.
            </p>           
            </div>
      </div>




      </div>

      </section>
      </div>
      </div> */}
      


      {/* <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            
            <div className="img left">
              <img src={about5} alt="" />
            </div>
          </div>
        </div>
      </section> */}


      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">

      {/* <div className="process">             
            <div className="process-left">
            <p className="half-textlarge">
            We as designers should be gatekeepers 
            to what we put out in the society.
            </p>
            <p>
            We have a responsibility for the impact 
            products and services we build are having 
            on people that are using them. I feel our 
            designs, the choices we make and the 
            decisions we make while designing are 
            very important. 
            </p>
            <p> 
            The user's experience is made up of design decisions. 
            We need to have the vision to see the unintended 
            consequences our decisions, designs, have on the society.     
            </p>            
            </div>
      </div>

      <div className="bluebg">
            <br />
            <p className="full-textlargebluebg">
            Personally, the way i want to design is by adopting a 
            human-planet-business centric approach to design; 
            while using technology as an enabler.  
            </p>
            <br />
      </div>

      <div className="process">             
            <div className="process-right">
            <p>
            This means designing experiences that 
            create value and well-being for its users, 
            are sustainable for the environment and 
            viable for businesses. I want to create
             holistic systems where both users and 
             business benefit and grow by solving 
             problems in a smart, ethical and sustainable way.
            </p>           
            </div>
      </div> */}

      <br />
      <br />
      <br />
      <div className="process">             
            <div className="process-left">
            <p className="half-textlarge">
            Awards I have proudly received
            </p>            
            </div>

            <div className="process-right">
            <div>
              <img src={awardsgroup} alt="" />
            </div>            
            </div>

      </div>

      {/* <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
                Some of the clients I have worked with
                </p>
            </div>
            <div className="process-right">           
               
             

              <div>
              <img src={clientgroup} alt="" />
              </div>
              
            </div>
      </div> */}

      {/* <div className="changebg">

          <br />
          <br />
          <div className="process">           
            <div className="process-left">
              <h4>
              Follow me on Medium where I have put 
              down some of my thoughts and mindset 
              towards Design.
              </h4>
              </div>
              <div className="process-right">
              <div>
              <img className="protologo" src={mediumlogo} alt="" />
              </div>
              
              <div className="resume-download">
              <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
              Read stories
              </a>
              </div> 
                             
              </div>
            </div>
            

            

            
      </div> */}

      </section>
      </div>
      </div> 

      

      

      {/* <section id="three" className="awards inner">
        <div className="title">
          <h2>awards i have proudly received.</h2>
        </div>
        <div className="awards-wrapper">
          <div className="awards-image">
            <img src={award1} alt="" />
          </div>
          <div className="awards-image">
            <img src={award2} alt="" />
          </div>
          <div className="awards-image">
            <img src={award3} alt="" />
          </div>
          <div className="awards-image">
            <img src={award4} alt="" />
          </div>
          <div className="awards-image">
            <img src={award5} alt="" />
          </div>
          <div className="awards-image">
            <img src={award6} alt="" />
          </div>
          <div className="awards-image">
            <img src={award7} alt="" />
          </div>
          <div className="awards-image">
            <img src={award8} alt="" />
          </div>
        </div>
      </section> */}

      

      <section id="footertopcta" className="inner">
        <p className="text arrow-front">
        <a href="/what-offer">now that you know a bit about me, 
        get to know my approach to design</a>
        </p>
        {/* <p className="text arrow-front">
        <a href="/what-offer">now that you know a bit about me, 
        find out what i can offer you</a>
        </p> */}
      </section>
    </div>
  </Layout>
)

export default Aboutme
